<template>
    <div class="navigation">
        <div class="navigation-container">
            <div class="header">
                <shared-common-logo/>
                <shared-common-logout/>
            </div>

            <shared-common-menu/>
        </div>
    </div>
</template>

<style scoped lang="scss">
    .navigation {
        display: flex;
        padding: var(--Padding-pa-20, 80px) var(--Padding-pa-10, 40px) var(--Padding-pa-8, 32px) var(--Padding-pa-10, 40px);
        flex-direction: column;
        align-items: flex-start;
        gap: var(--Padding-pa-30, 120px);
        flex-shrink: 0;
        align-self: stretch;
        
        @include responsive( 'mobile' ) { 
            width: inherit;
            padding: 0;
        }
        @include responsive( 'desktop' ) {
            width: 240px;
            padding: 60px var(--Padding-pa-6, 24px);
        }
        @include responsive( 'desktop-small' ) {
            padding: 80px 40px;
            width: 360px;
        }

        .navigation-container {
            display: flex;
            flex-direction: column;
            flex: 1 0 auto;
            align-items: flex-start;
            align-self: stretch;
            gap: 120px;
            
            @include responsive( 'mobile' ) {
                justify-content: center;
                align-items: center;
                flex: inherit;
                gap: 0;
            }
            
            .header {
                @include responsive('mobile') { 
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    align-self: stretch;
                    padding: 20px 12px;
                }

                .logo {
                    display: flex;
                    padding: 0px 12px;
                    align-items: flex-start;
                    gap: var(--Padding-pa-6, 24px);
                    color: $logo-color;
                }

                .logout {
                    display: none;
                    @include responsive('mobile') { 
                        display: flex;
                    }
                }
            }
        }
    }
</style>